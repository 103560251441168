import { createRouter, createWebHashHistory,createWebHistory } from "vue-router";


const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes: [
        {path: "/",redirect: '/home'},
        {path: '/home', name: 'home',
            component: () => import('../views/home.vue'),
            meta: {title: '', layout: 'default', requireAuth: true,}
        },
        {path: '/jobList', name: 'jobList',
            component: () => import('../views/jobList.vue'),
            meta: {title: '职位列表', layout: 'default', requireAuth: true,}
        },
        {path: '/corporationList', name: 'corporationList',
            component: () => import('../views/corporationList.vue'),
            meta: {title: '企业列表', layout: 'default', requireAuth: true,}
        },
        {path: '/resumeListWeb', name: 'resumeListWeb',
            component: () => import('../views/resumeListWeb.vue'),
            meta: {title: '简历列表', layout: 'default', requireAuth: true,}
        },
        {path: '/AI', name: 'AI',
            component: () => import('../views/AI.vue'),
            meta: {title: 'AI面试', layout: 'default', requireAuth: true,}
        },
        {path: '/scanCode', name: 'scanCode',
            component: () => import('../views/scanCode.vue'),
            meta: {title: '扫码面试', layout: 'default', requireAuth: true,}
        },
    ],
});

export default router

