import common from './common'
const filters = {
    getExperience(value){
        if(common.isEmpty(value)){
            return "";
        }
        switch (value){
            case 0:
                return '不限';
            case 1:
                return "一年以下";
            case 2:
                return "1-3年";
            case 3:
                return "3-5年";
            case 4:
                return "5-10年";
            case 5:
                return "10年以上";
            default:
                return '不限'
        }
    },
    getNatureText: function (value){
        let natureArr = [
            "其他",
            "外商独资",
            "中外合资",
            "民营企业",
            "国有企业",
            "国内上市企业",
            "政府机关/非营利机构",
            "事业单位",
        ]
        let code = -1;
        if(typeof value =='string'){
            try {
                code = parseInt(value)
            }
            catch (e) {
                console.log(e)
            }
        }
        else if(typeof value == 'number'){
            code = parseInt(value);
        }
        else{
            return "";
        }

        return natureArr[code];
    },
    getEnterpriseScale(value){
        if(common.isEmpty(value)){
            return "";
        }
        switch (value){
            case 1:
                return "0-20人";
            case 2:
                return "20-50人";
            case 3:
                return "50-100人";
            case 4:
                return "100-500人";
            case 5:
                return "500人以上";
            default:
                return ''
        }
    },
    getDate: function(value) {
        if(common.isEmpty(value)){
            return "";
        }

        if(value == -999999999999){
            return "至今";
        }

        return common.formatDate(value,"yyyy.MM.dd hh:mm");
    },
    getDate1: function(value) {
        if(common.isEmpty(value)){
            return "";
        }

        if(value == -999999999999){
            return "至今";
        }

        return common.formatDate(value,"yyyy.MM.dd");
    },
    getSex(value,defaultVal) {
        if(value =='' || value == null || value ==undefined || value == "null"){
            return defaultVal;
        }
        // return value;

        switch(value){
            case 1:
                return "男";
            case 2:
                return "女";

            default:
                return defaultVal;
        }
    },
    getDefault(value,defaultVal) {
        if(value =='' || value == null || value ==undefined || value == "null"){
            return defaultVal;
        }
        return value;
    },
    getLaborTypeText(value,defaultVal) {
        if(value === '' || value === null || value === undefined || value === "null"){
            return defaultVal;
        }
        let keyNum = parseInt(value)
        switch(keyNum){
            case 0:
                return '其他';
            case 1:
                return '边缘户';
            case 2:
                return '退捕鱼民';
            case 3:
                return '脱贫劳动力';
            case 4:
                return '异地搬迁脱贫劳动力';
            case 5:
                return '高校毕业生';
            case 6:
                return '农村转移就业劳动力';
            case 7:
                return '退伍军人';
            case 8:
                return '城镇就业困难户';
            case 9:
                return '残疾人员';
            case 10:
                return '低保户';
            case 11:
                return '戒毒康复人员';
            case 12:
                return '刑满释放人员';
            default:
                return '';
        }
    },
    getJobNatureText(value,defaultVal) {
        switch(value){
            case 0:
                return "全职";
            case 1:
                return "兼职";
            case 2:
                return "临时工";
            default:
                return defaultVal;
        }
    },
    getSwitch(value, defaultVal) {
        if(value === '' || value === null || value === undefined || value === "null"){
            return defaultVal;
        }
        switch(value){
            case 0:
                return "否";
            case 1:
                return "是";
            default:
                return defaultVal;
        }
    },
    getHadSkillCertificate(value, defaultVal) {
        if(value === '' || value === null || value === undefined || value === "null"){
            return defaultVal;
        }
        switch(value){
            case 0:
                return "未获得";
            case 1:
                return "已获得";

            default:
                return defaultVal;
        }
    },
    getMonth(date) {
        if(date == '' || date == null || date == undefined || date == "null"){
            return '未填写';
        }

        date = new Date(date);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        var d = date.getDate();
        var h = date.getHours();
        var m1 = date.getMinutes();
        var s = date.getSeconds();
        m = m < 10 ? ("0" + m) : m;
        d = d < 10 ? ("0" + d) : d;
        return y + "-" + m ;
    }

}
export default filters;
