let common = {};


/*中国手机号验证*/
common.checkCellPhoneCN = (phoneNumber) => {
    let reg = /^1\d{10}$/;
    return reg.test(phoneNumber);
};

/*邮箱格式验证*/
common.checkEmailFormat = (emailAddr) => {
    let reg = /^([a-zA-Z0-9]+(_|-|.)?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
    return reg.test(emailAddr);
};

/*只包含英文字母验证*/
common.check_En_letter_only = (input_value) => {
    let reg = /^[A-Za-z]+$/;
    return reg.test(input_value);
};
/*只包含数字、下划线和英文验证*/
common.check_En_letter_num_only = (input_value) => {
    let reg = /^[A-Za-z0-9_.]+$/;
    return reg.test(input_value);
};

common.isEmpty = (value) => {
    if(value === undefined || value === null || value === ''){
        return true;
    }

    return false;
}
common.isEmptyArray = (value) => {
    if(value === undefined || value === null || value.length == 0){
        return true;
    }

    return false;
}

common.formatDate = (dateStr, fmt) =>{

    if(dateStr == undefined || dateStr == null || dateStr == ''){
        return '';
    }

    let date = new Date(dateStr);

    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    }
    return fmt;
}

common.timeFormat2 = (inputTime) =>{

    if(inputTime == null)
    {
        return "";
    }

    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d;
};

common.timeFormat3 = (inputTime) =>{

    if(inputTime == null)
    {
        return "";
    }

    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + m + d;
};

function padLeftZero(str) {
    return ('00' + str).substr(str.length);
}
common.generateUUID =() => {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
};

common.getUrlDomain = () =>{
    var url = window.location.href;
    if(url != null || url != ''){
        var domain = url.split('/')[2];

        return domain.replace(".sangucloud.com","");
    }

    return "enttest";
}

common.saveAs = (blob,downName) => {
    var fileURL = window.URL.createObjectURL(blob);
    var fileLink = document.createElement('a');
    
    fileLink.href = fileURL;
    fileLink.setAttribute('download', downName);
    document.body.appendChild(fileLink);
    
    fileLink.click();

    document.body.removeChild(fileLink);
}

// common.jsonToFormData = (jsonObject) => {
//     const formData = new FormData();
//
//   for (const key in jsonObject) {
//     if (jsonObject.hasOwnProperty(key)) {
//       formData.append(key, jsonObject[key]);
//     }
//   }
//
//   return formData;
// };

common.getCurrentYearAndMonth = () =>{
    // 创建一个表示当前日期的 Date 对象
    const currentDate = new Date();

    // 获取当前年份
    const currentYear = currentDate.getFullYear();

    // 获取当前月份（注意：getMonth 返回的是 0 到 11，所以需要加 1）
    const currentMonth = currentDate.getMonth() + 1;

    // 获取当前日期
    const currentDay = currentDate.getDate();

    // 将年份、月份和日期转换为字符串，并确保它们是两位数
    const yearString = currentYear.toString();
    const monthString = currentMonth < 10 ? '0' + currentMonth.toString() : currentMonth.toString();
    const dayString = currentDay < 10 ? '0' + currentDay.toString() : currentDay.toString();

    // 组合年份、月份和日期字符串
    const currentYearMonthDayString = yearString + '-' + monthString + '-' + dayString;

    return currentYearMonthDayString
}

common.checkEmpty = (value) => {
    if(value == -1){
        return '';
    }

    return value;
}

export default common;
